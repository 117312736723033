<template>
  <button
    class="ch_button_wrapper"
    :class="classes"
    :disabled="loading ? true : disabled"
    v-bind="$attrs"
    v-on="$listeners"
    type="button"
    style="font-size: 12px"
  >
    <span v-if="loading" class="ting-loadingIndicator" />
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes: function () {
      return {
        [`ch-type-${this.type}`]: this.type,
      };
    },
  },
};
</script>

<style lang="scss">
.ch_button_wrapper {
  box-sizing: border-box;
  padding: 7px 15px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  background: white;
  font-size: 12px;
  border-radius: 4px;
  transition: background 0.25s;
  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }

  &.ch-type-full {
    width: 100%;
    color: #fff;
    border: 1px solid #3e7eff;
    background-color: #3e7eff;
    &:hover,
    &:focus {
      color: #fff;
      background: #255ed7;
      border: 1px solid #2c69e3;
    }
  }

  &.ch-type-default {
    color: #3e7eff;
    border: 1px solid #3e7eff;
    background-color: #ecf5ff;
    &:hover,
    &:focus {
      color: #fff;
      background: #3e7eff;
      border: 1px solid #3e7eff;
    }
  }
  &.ch-type-ok {
    margin: 6px 12px;
    color: #fff;
    border: 1px solid #3e7eff;
    background-color: #3e7eff;
    &:hover,
    &:focus {
      color: #fff;
      background: #255ed7;
      border: 1px solid #2c69e3;
    }
  }
  &.ch-type-cancel {
    margin: 6px 12px;
    color: #3e7eff;
    border: 1px solid #3e7eff;
    background-color: #fff;
    &:hover,
    &:focus {
      color: #3e7eff;
      background: #ecf5ff;
      border: 1px solid #3e7eff;
    }
  }
  &.ch-type-link {
    padding: 0 6px;
    background: none;
    border: none;
    color: #3e7eff;
    &:hover,
    &:focus {
      background: none;
      border: none;
      color: #7c9bd9;
    }
  }
  &.ch-type-delete {
    padding: 0 6px;
    background: none;
    border: none;
    color: #fb3a39;
    &:hover,
    &:focus {
      background: none;
      border: none;
      color: #dc8989;
    }
  }
  &.ch-type-up {
    padding: 0 6px;
    background: none;
    border: none;
    color: #10C434;
    &:hover,
    &:focus {
      background: none;
      border: none;
      color: #75ec8d;
    }
  }
  &.ch-type-down {
    padding: 0 6px;
    background: none;
    border: none;
    color: #FFA800;
    &:hover,
    &:focus {
      background: none;
      border: none;
      color: #f5ca79;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    color: #fff;
    background-color: #ddd;
    border: none;
    &:hover,
    &:focus {
      cursor: not-allowed;
      color: #fff;
      background-color: #ddd;
      border: none;
    }
  }
  > .ting-loadingIndicator {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin-right: 4px;
    border-radius: 8px;
    border-color: lighten(#4ff3d4, 20%) lighten(#4ff3d4, 10%) #4ff3d4
      transparent;
    border-style: solid;
    border-width: 2px;
    animation: ting-spin 1s infinite linear;
    &:hover,
    &:focus {
      cursor: not-allowed;
      color: #fff;
      background-color: #ddd;
      border: none;
    }
  }
  @keyframes ting-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@media screen  and (max-width: 1440px) {
  .ch_button_wrapper {
    padding: 2px 4px;
    font-size: 10px;
  }
}
</style>