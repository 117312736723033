import Vue from 'vue'
import '@/assets/css/element/element-variables.scss'
import Button from "element-ui/lib/button";
import Menu from "element-ui/lib/menu";
import Switch from "element-ui/lib/switch";
import Input from "element-ui/lib/input";
import Dialog from "element-ui/lib/dialog";
import Icon from "element-ui/lib/icon";
import Upload from "element-ui/lib/upload";
import Tree from "element-ui/lib/tree";
import Popover from "element-ui/lib/popover";
import Select from "element-ui/lib/select";
import Submenu from "element-ui/lib/submenu";
import MenuItem from "element-ui/lib/menu-item";
import InputNumber from "element-ui/lib/input-number";
import Radio from "element-ui/lib/radio";
import RadioGroup from "element-ui/lib/radio-group";
import RadioButton from "element-ui/lib/radio-button";
import Checkbox from "element-ui/lib/checkbox";
import CheckboxButton from "element-ui/lib/checkbox-button";
import CheckboxGroup from "element-ui/lib/checkbox-group";
import Option from "element-ui/lib/option";
import OptionGroup from "element-ui/lib/option-group";
import Table from "element-ui/lib/table";
import TableColumn from "element-ui/lib/table-column";
import Form from "element-ui/lib/form";
import FormItem from "element-ui/lib/form-item";
import Tabs from "element-ui/lib/tabs";
import TabPane from "element-ui/lib/tab-pane";
import Tag from "element-ui/lib/tag";
import Badge from "element-ui/lib/badge";
import Dropdown from "element-ui/lib/dropdown";
import Avatar from "element-ui/lib/avatar";
import DropdownMenu from "element-ui/lib/dropdown-menu";
import Pagination from "element-ui/lib/pagination";
import Cascader from "element-ui/lib/cascader";
import Image from "element-ui/lib/image";
import Loading from "element-ui/lib/loading";
import MessageBox from "element-ui/lib/message-box";
import Notification from "element-ui/lib/notification";
import Message from "element-ui/lib/message";
import datePicker from "element-ui/lib/date-picker";
import Card from "element-ui/lib/card";
import timeline from "element-ui/lib/timeline";
import timelineItem from "element-ui/lib/timeline-item";
import tooltip from "element-ui/lib/tooltip";
import result from "element-ui/lib/result"
import dropdownItem from "element-ui/lib/dropdown-item"
import dropdownMenu from "element-ui/lib/dropdown-menu"
import chprompt from "@/components/Prompt/prompt"
import Carousel from "element-ui/lib/carousel"
import carouselItem from "element-ui/lib/carousel-item"
import Row from "element-ui/lib/row"
import Col from "element-ui/lib/col"
import  TimeSelect from "element-ui/lib/time-select"  //在线投票追加
import Rate from "element-ui/lib/rate"
import Divider from "element-ui/lib/divider"
import Steps from "element-ui/lib/steps"
import Step from "element-ui/lib/step"
Vue.use(TimeSelect);//在线投票追加
Vue.use(Button);Vue.use(Dialog);Vue.use(Switch);Vue.use(Select);
Vue.use(Icon);Vue.use(Upload);Vue.use(Tree);Vue.use(Popover);
Vue.use(Menu);Vue.use(Submenu);Vue.use(MenuItem);
Vue.use(Input);Vue.use(InputNumber);Vue.use(Option);Vue.use(OptionGroup);
Vue.use(Radio);Vue.use(RadioGroup);Vue.use(RadioButton);
Vue.use(Checkbox);Vue.use(CheckboxButton);Vue.use(CheckboxGroup);
Vue.use(Table);Vue.use(TableColumn);Vue.use(Form);Vue.use(FormItem);
Vue.use(Tabs);Vue.use(TabPane);Vue.use(Tag);
Vue.use(Badge);Vue.use(Dropdown);Vue.use(Avatar);Vue.use(DropdownMenu);
Vue.use(Pagination);Vue.use(Image);Vue.use(Cascader)
Vue.use(datePicker);Vue.use(datePicker);
Vue.use(Card);Vue.use(Card);Vue.use(Row);Vue.use(Col);
Vue.use(timeline);Vue.use(timeline);
Vue.use(timelineItem);
Vue.use(tooltip);
Vue.use(result);
Vue.use(dropdownItem);
Vue.use(dropdownMenu);
Vue.use(Carousel);
Vue.use(carouselItem).use(Rate).use(Divider).use(Steps).use(Step);

Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$datePicker = datePicker;
Vue.prototype.$card = Card;
Vue.prototype.$timeline = timeline;
Vue.prototype.$timelineItem = timelineItem;
Vue.prototype.$chprompt = chprompt
Vue.prototype.$carouselItem = carouselItem
