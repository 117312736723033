<template>
  <svg class="CH_icon" aria-hidden="true" v-on="$listeners">
    <use :xlink:href="'#'+name"/>
  </svg>
</template>

<script>
const importAll = (requireContext) => requireContext.keys().forEach(requireContext);
try { importAll(require.context('@/assets/icons', true, /\.svg$/))}
catch (error) { console.log(error,'error') }
export default {
  name: 'Icon',
  props: {
    name: {type: String, required: true}
  },
};
</script>

<style lang="scss" scoped>

.CH_icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.25em;
  overflow: hidden;
  fill: currentColor;
}

</style>